.App {
  text-align: center;
}

body {
  font-family: ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}

p {
  font-family: ui-sans-serif,system-ui,-apple-system,Segoe UI;
  font-weight: 500;
}

.break-line {
  white-space: pre-line;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Header Container */
.my-navbar {
  padding-top: 0px;
  padding-bottom: 0px;
}
.head-frame {
  border-bottom: 1px solid #f1ceea;
  width: 100%;
  height: 52px;
  background-color: rgba(250, 161, 213, 0.2);
}
.head-image {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  opacity: 0.7;
}

.tail-container {
  height: 80px;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

/* Main Container */
.main-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  min-height: 400px;
  width: 100%;
}
.container-center {
  /* background-color: rgba(250, 161, 213, 0.2); */
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  /* display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center; */
}
.top-border {
  border-top: 1px solid rgba(197, 194, 196, 0.5);
  padding-top: 10px;
}
.container-full {
  width: 100vw;
  height: calc(100vh - 55px);
  display: flex;
}
.container-left {
  width: 220px;
  height: 100%;
  overflow-y: auto;
}
.container-right {
  width: calc(100vw - 220px);
  height: 100%;
  border-left: 1px solid #f1ceea;
}

.screen-height {
  height: calc(100vh - 160px);
}

.chatgpt-title {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-bottom: 1px solid rgba(197, 194, 196, 0.5);
}
.chatgpt-title-text {
  margin-top: 11px;
}

.sub-right-container {
  width: 100%;
  height: 100%;
  display: flex;
}
.sub-left {
  width: 250px;
  height: calc(100% - 30px);
  overflow-y: auto;
  overflow-x: hidden;
}
.sub-right {
  width: calc(100% - 250px);
  height: calc(100% - 30px);
  border-left: 1px solid #f1ceea;
  padding-left: 10px;
  padding-right: 10px;
}
.chat-box-container {
  width: calc(100% - 20px);
  height: calc(100% - 75px);
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto;
}
.chat-box {
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}
.chat-box-me {
  border-bottom: 1px solid rgba(23, 143, 61, 0.1);
}
.chat-box-ai {
  border-bottom: 1px solid rgba(241, 206, 234, 0.5);
}
.chat-box-image {
  width: 40px;
  height: 40px;
  border: 1px solid rgb(68, 126, 86);
  border-radius: 5px;
  opacity: 0.5;
}
.chat-box-image-text {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(68, 126, 86, 0.5);
  border-radius: 5px;
  background-color: rgba(68, 126, 86, 0.2);
  text-align: center;
  padding-top: 7px;
}
.chat-box-text {
  width: calc(100% - 50px);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.chat-box-text-time {
  font-size: 13px;
  color: rgb(197, 194, 196);
}
.chat-box-sent-image {
  max-width: 90%;
  min-width: 250px;
  height: auto;
}

.chat-input-container {
  /* position: absolute; */
  bottom: -0px;
  width: calc(100% - 10px);
  max-height: 250px;
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  margin-bottom: 0px;
  margin-top: 5px;
}
.textarea-input {
  height: auto;
  max-height: 200px;
  resize: vertical;
}

/* React-Bootstrap */
.nav-link:hover {
  background-color: rgba(23, 143, 61, 0.2);
}
.nav-link.active {
  background-color: rgba(23, 143, 61, 0.1);
}

/** Login Page **/
.background {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: linear-gradient(to bottom, #a3ff9e, #008000);
}
.login-container {
  width: 100%;
  height: 100%;
  max-width: 500px;
  position: relative;
}
.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50% - 60px);
  right: 20px;
  height: 340px;
  width: 332px;
  border-radius: 10px;
  font-size: 20px;
  color: black;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-color: rgba(68, 126, 86, 0.9);
  z-index: 10;
}
.login-label {
  color: white;
}
.login input[type="text"] {
  font-size: 20px;
  width: 300px;
}
.login input[type="password"] {
  font-size: 20px;
  width: 300px;
}
.login button {
  font-size: 20px;
  width: 160px;
  margin-top: 30px;
}
.login form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-reg {
  margin-top: 20px;
  font-size: 17px;
  font-weight: 600;
  color: rgb(246, 78, 215);
}
.bg-line {
  width: calc(100vw - 100px);
  height: 1px;
  background-color: rgba(7, 7, 7, 0.3);
  position: absolute;
  top: calc(50% + 180px);
  left: 50px;
  z-index: 1;
}
.login-logo {
  width: 100%;
  max-width: 376px;
  height: 600px;
  opacity: 0.6;
  position: absolute;
  top: calc(50% - 300px);
  right: 0px;
  z-index: 0;
}
.login-version {
  display: block;
  position: absolute;
  width: 120px;
  right: 40px;
  top: calc(50% - 95px);
  font-size: 15px;
  color: rgba(237, 219, 206, 0.5);
}

/** ID Photo Page **/
.photo-detail-container {
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
}
.photo-detail-row {
  width: 100%;
  margin-top: 30px;
  min-height: 20px;
}
.photo-img-container {
  width: calc(100% - 40px);
  max-width: 430px;
  height: 400px;
  border: 1px solid rgba(68, 126, 86, 0.5);
  border-radius: 5px;
  margin-left: 20px;
  margin-bottom: 10px;
  display: block;
  float: left;
}
.photo-detail-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.output-info {
  display: block;
  width: 100%;
  min-height: 20px;
}
.photo-desc-container {
  width: calc(100% - 40px);
  max-width: 430px;
  display: block;
  float: left;
  margin-left: 20px;
}
.block-center {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

/** Configuration Page **/
.configure-block-title {
  width: 100%;
  margin-top: 50px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.configure-block-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-top: 2px solid rgba(68, 126, 86, 0.5);
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
.row-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
}

.session-group-title {
  width: 100%;
  margin-top: 0px;
  padding-left: 5px;
  background-color: #9ba8a4;
  border-radius: 3px;
}
